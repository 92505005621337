import React from "react";
import styled from "styled-components";

const FooterContainer = styled.footer`
  margin-top:64px;
	max-width: var(--container-width);
	margin-left:auto;
	margin-right:auto;
	border-top:2px solid var(--text);
	padding-top:24px;
`

const Email = styled.a`
	font-size: var(--h5);
	margin-bottom:24px;
	display:block;
`
const Footer = () => {
	return (
		<FooterContainer>
			<Email href="mailto:info@dlanham.co.za">info@dlanham.co.za</Email>
		</FooterContainer>
	);
};

export default Footer;
