import React from 'react';
import styled from 'styled-components';

const ButtonContainer = styled.a`
	display: block;
	background: var(--primary);
	color: white;
	padding: 8px 16px;
	@media screen and (min-width: 768px) {
		padding: 12px 30px;
	}
`;

const Button = ({ url, children, className }) => {
	return (
		<ButtonContainer href={url} target='_blank' className={className}>
			{children}
		</ButtonContainer>
	);
};

export default Button;
