import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import DarkModeToggle from './darkModeToggle';

const Menu = styled.nav`
	margin-left: auto;
	padding-top: 16px;
	max-width: var(--container-width);
	margin-right: auto;
	margin-bottom: 32px;
	@media screen and (min-width: 768px) {
		display: flex;
		padding-top: 40px;
		margin-bottom: 56px;
	}
`;

const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 100%;
	@media screen and (min-width: 768px) {
		gap: 16px;
		flex-direction: row;
		margin-bottom: 0;
		align-items: center;
	}
`;

const NavDarkModeToggle = styled(DarkModeToggle)`
	position: absolute;
	top: 16px;
	right: 16px;
	@media screen and (min-width: 768px) {
		position: relative;
		top: 0;
		right: 0;
	}
`;
const Title = styled(Link)`
	&:hover {
		text-decoration: underline;
	}
`;
const Subtitle = styled.p`
	font-size: var(--small);
	@media screen and (min-width: 768px) {
		font-size: inherit;
	}
	color: var(--accent);
	margin-right: auto;
	line-height: 1.5;
`;

const Nav = () => {
	return (
		<Menu>
			<TitleContainer>
				<Title to='/'>David Lanham</Title>
				<Subtitle>Design &amp; frontend development</Subtitle>
				<NavDarkModeToggle />
			</TitleContainer>
		</Menu>
	);
};

export default Nav;
