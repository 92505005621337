import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import 'minireset.css';
import Nav from './nav';
import Footer from './footer';
import { Helmet } from 'react-helmet';

const GlobalStyle = createGlobalStyle`

  body {
    background-color:var(--background);
    &.dark{
      --text: white;
      --background : #202023;
      --background-knocked: #ffffff15; 
      --primary: #25834a;
      --link: white;
      --accent: #ccc;
      .toggle{
        background: var(--text);
        .sun {
          transform: translateY(0);
          z-index: 1;
        }
        .moon {
          transform: translateY(100%);
          z-index: 0;
        }
      }
    }
    --text: black;
    --background : white;
    --background-knocked: #00000015; 
    --primary: #25834a;
    --accent: #666;
    --link: #000;
    --h1: 2.441rem;
    --h2: 1.953rem;
    --h3: 1.563rem;
    --h4: 1.25rem;
    --h5: 1.125rem;
    --small: 0.8rem;
    @media screen and (min-width:768px){
    --h1: 3.052rem;
    --h2: 2.441rem;
    --h3: 1.953rem;
    --h4: 1.563rem;
    --h5: 1.25rem;
    }
    @media screen and (min-width:1024px){
    --container-width:80%;
    }
    @media screen and (min-width:1700px){
      --container-width:1560px;
    }
    color: var(--text);
    font-family: 'Inter', sans-serif;
    font-size: 100%; /*16px*/
    line-height: 1.5;
    padding:0 24px;
    @media screen and (min-width:768px){
      font-size: 112.5%; /*18px*/
    }
    .toggle{
      background: var(--link);
      .sun {
        transform: translateY(100%);
        z-index: 0;
      }
      .moon {
        transform: translateY(0);
        z-index: 1;
      }
    }
  }
  a{
    text-decoration:none;
    color: var(--link);
  }
  h1, h2, h3, h4, h5 {
    line-height: 1.5;
  }
  h1 {font-size: var(--h1)}
  h2 {font-size: var(--h2)}
  h3 {font-size: var(--h3)}
  h4 {
    font-size: var(--h4);
  }
  h5 {font-size: var(--h5)}
  small, .text_small {
    font-size: var(--small);
  }

`;

const Body = styled.div``;

const Main = styled.div`
	max-width: var(--container-width);
	margin: 0 auto;
	display: flex;
	flex-direction: column;
`;

const Layout = ({ children }) => {
	return (
		<Body>
			<Helmet>
				<link rel='preconnect' href='https://fonts.gstatic.com' />
				<link href='https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap' rel='stylesheet' />
			</Helmet>
			<Nav />
			<Main>{children}</Main>
			<Footer />
			<GlobalStyle />
		</Body>
	);
};

export default Layout;
