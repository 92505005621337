import React from 'react';
import styled from 'styled-components';
import { ThemeToggler } from 'gatsby-plugin-dark-mode';
import moonIcon from '../images/moon.svg';
import sunIcon from '../images/sun.svg';

const Toggle = styled.div`
	width: 32px;
	height: 32px;
	border-radius: 50%;
	order: 1;
	position: relative;
	cursor: pointer;
	overflow: hidden;
	img {
		transition: transform 0.25s;
	}
`;

const IconContainer = styled.div`
	position: absolute;
	transition: transform 0.15s;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	img {
		width: 100%;
		height: 100%;
		padding: 6px;
		top: 0;
		bottom: 0;
		left: 0;
		position: absolute;
	}
`;

const DarkModeToggle = ({ className }) => {
	function changeTheme(theme, toggleTheme) {
		theme === 'dark' ? toggleTheme('light') : toggleTheme('dark');
	}
	return (
		<>
			<ThemeToggler>
				{({ theme, toggleTheme }) => (
					<Toggle className={['toggle', theme, className]} onClick={() => changeTheme(theme, toggleTheme)}>
						<IconContainer className='sun'>
							<img src={sunIcon} alt='sun' />
						</IconContainer>
						<IconContainer className='moon'>
							<img src={moonIcon} alt='moon' />
						</IconContainer>
					</Toggle>
				)}
			</ThemeToggler>
		</>
	);
};

export default DarkModeToggle;
