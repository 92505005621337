import React from 'react';
import styled, { css } from 'styled-components';
import Button from './button';

const Header = styled.header`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	flex-wrap: wrap;
	margin-bottom: 32px;
	@media screen and (min-width: 768px) {
		margin-bottom: 64px;
		flex-direction: row;
	}
`;

const ProjectDate = styled.span`
	display: block;
	color: var(--accent);
`;

const HeadingText = styled.div`
	padding-right: 32px;
	margin-right: auto;
`;

const HeadingButton = styled(Button)`
	margin-top: 16px;
	${props =>
		!props.url &&
		css`
			background: var(--text);
			color: var(--background);
			opacity: 0.5;
			pointer-events: none;
		`}
`;

const Heading = styled.h2``;
const PageHeading = ({ text, url, button, date, linkText, inDevelopment }) => {
	return (
		<Header>
			<HeadingText>
				{inDevelopment ? <ProjectDate>In development</ProjectDate> : date && <ProjectDate>{date}</ProjectDate>}
				<Heading>{text}</Heading>
			</HeadingText>
			{button && <HeadingButton url={url}>{linkText}</HeadingButton>}
		</Header>
	);
};

export default PageHeading;
